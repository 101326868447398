import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Aligner = makeShortcode("Aligner");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "la-verdadera-tienda",
      "style": {
        "position": "relative"
      }
    }}>{`La verdadera tienda`}<a parentName="h1" {...{
        "href": "#la-verdadera-tienda",
        "aria-label": "la verdadera tienda permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <h2 {...{
      "id": "porqué",
      "style": {
        "position": "relative"
      }
    }}>{`Porqué`}<a parentName="h2" {...{
        "href": "#porqu%C3%A9",
        "aria-label": "porqué permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Levantamos el velo frente a nuestro navegador, besamos contratos inteligentes desde la consola e incluso implementamos nuestros propios votos especiales, porque todo está interconectado. Ahora es el momento de ver por qué ni siquiera la muerte puede separarnos. ¿Es realmente cierto que un contrato inteligente es para siempre? ¿Cómo funciona realmente su almacenamiento? ¿Qué significa delegar el acceso a esta tienda permanente? ¿Existe una variable "privada" en una red pública como Ethereum?`}</p>
    <p>{`Todo esto y más nos espera a medida que continuamos nuestro viaje hacia la verdad compartida.`}</p>
    <h2 {...{
      "id": "preparación",
      "style": {
        "position": "relative"
      }
    }}>{`Preparación`}<a parentName="h2" {...{
        "href": "#preparaci%C3%B3n",
        "aria-label": "preparación permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ol>
      <li parentName="ol">{`Asegúrate de que todavía te quede algo de Sepolia ETH en tu cuenta.`}</li>
      <li parentName="ol">{`Revisa Remix y el trabajo que hicimos allí la semana pasada. Sólo lo usaremos una vez, al final de nuestra sesión de hoy, pero aun así vale la pena familiarizarse con él.`}</li>
      <li parentName="ol">{`Siéntate y respira tranquilamente durante al menos 15 minutos.`}</li>
    </ol>
    <h2 {...{
      "id": "aplicación",
      "style": {
        "position": "relative"
      }
    }}>{`Aplicación`}<a parentName="h2" {...{
        "href": "#aplicaci%C3%B3n",
        "aria-label": "aplicación permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Vamos a investigar el almacenamiento en Ethereum completando dos desafíos de Ethernaut: Delegation y Vault. Esto nos llevará a una mirada de nivel mucho más bajo a algunas de las operaciones de la máquina virtual Ethereum y las diferentes "ranuras" asociadas con cada contrato. Incluso podemos intentar separar la voluntad de los intentos. Si el tiempo lo permite, también atacaremos Privacy.`}</p>
    <p>{`Después de usar algunos métodos web3 nuevos y divertidos para investigar qué esconde cada contrato, finalizaremos la sesión con otro contrato propio para atacar la Vault como kamikazes, además de discutir sobre compiladores y compañeros.`}</p>
    <h2 {...{
      "id": "resumen",
      "style": {
        "position": "relative"
      }
    }}>{`Resumen`}<a parentName="h2" {...{
        "href": "#resumen",
        "aria-label": "resumen permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`En esta sesión habrás aprendido:`}</p>
    <ol>
      <li parentName="ol">{`Cómo funcionan las llamadas de bajo nivel en el EVM y las cosas extrañas y maravillosas, aunque peligrosas, para las que se pueden utilizar.`}</li>
      <li parentName="ol">{`Cómo se almacena la información en los contratos inteligentes y, por tanto, en Ethereum en su conjunto.`}</li>
      <li parentName="ol">{`Qué hace que la información sea pública y permanente.`}</li>
      <li parentName="ol">{`Más información sobre los métodos API de web3 y cómo utilizarlos para crear tipos específicos de transacciones, directamente desde tu navegador.`}</li>
      <li parentName="ol">{`Qué hacen los compiladores y cómo utilizar sus diferentes versiones.`}</li>
    </ol>
    <h2 {...{
      "id": "grabación",
      "style": {
        "position": "relative"
      }
    }}>{`Grabación`}<a parentName="h2" {...{
        "href": "#grabaci%C3%B3n",
        "aria-label": "grabación permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/9atqMRJLEoU" mdxType="Video" />
    <Aligner center mdxType="Aligner">
      <Image alt="John of Patmos" src="/images/build/sealed_book.jpg" mdxType="Image" />
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      